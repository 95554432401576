import { routeUrls } from './routeUrls';

export const scmNavLinks: object = {
  navigation: [
    {
      key: 'manage',
      title: 'Manage',
      expandable: true,
      // path: '/manage',
      noRBACheck: true,
      exact: false,
      mergeNav: true,
      children: [
        {
          key: 'configuration',
          title: 'Configuration',
          // path: '/manage/configuration',
          noRBACheck: true,
          exact: false,
          mergeNav: true,
          contentClassName: 'panwds-tw3',
          children: [
            {
              key: 'adns-resolver',
              title: 'ADNS Resolver',
              path: routeUrls.adnsResolver,
              noRBACheck: true,
              contentClassName: 'panwds-tw3',
              component: () => import('./Routes'),
            },
          ],
        },
      ],
    },
  ],
};
