import { scmNavLinks } from '@routes/scm-nav-links';
import { getAuthState } from '@sparky/framework';

export default async function init() {
  return function tsgLoad() {
    if (checkMfePSIfEnabled()) {
      return scmNavLinks;
    }
    return null;
  };
}

const MFE_PROPERTY_NAME_DEPRECATED = 'zcs.settings';
const MFE_PROPERTY_NAME = 'zdns.settings';

// check MFE property store to see the tenant has access to the adns
function checkMfePSIfEnabled(): boolean {
  const authState = getAuthState();
  const authStateProperties = authState?.properties;

  const appEnabledDeprecatedProp = authStateProperties?.get(MFE_PROPERTY_NAME_DEPRECATED);
  const appEnabledDeprecated =
    appEnabledDeprecatedProp !== undefined ? appEnabledDeprecatedProp?.enabled : false;

  const appEnabledProp = authStateProperties?.get(MFE_PROPERTY_NAME);
  const appEnabled = appEnabledProp !== undefined ? appEnabledProp?.enabled : false;

  return appEnabled || appEnabledDeprecated;
}
