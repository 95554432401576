const rootPath = '/app-edge';
const adnsResolver = `${rootPath}/adns-resolver`;

export const routeUrls = {
  rootPath,
  adnsResolver,
  securityProfiles: `${adnsResolver}/security-profiles`,
  addSecurityProfile: `${adnsResolver}/security-profiles/add`,
  editSecurityProfile: `${adnsResolver}/security-profiles/edit`,
  addOverrides: `${adnsResolver}/security-profiles/add/add-overrides`,
  editOverrides: `${adnsResolver}/security-profiles/edit/add-overrides`,
  manageDnsMisconfigDomains: `${adnsResolver}/security-profiles/edit/dns-misconfig-domains`,

  networkObjects: `${adnsResolver}/network-objects`,
  sinkholeSettings: `${adnsResolver}/settings`,
  settingsPreview: `${adnsResolver}/settings/preview`,

  addNetworkObject: `${adnsResolver}/add-network-object`,
  addNetworkObjectConfirmation: `${adnsResolver}/add-network-object/confirmation`,
  editNetworkObject: `${adnsResolver}/edit-network-object`,
  manageSubnets: `${adnsResolver}/edit-network-object/subnets`,
  addSubnet: `${adnsResolver}/edit-network-object/subnets/add`,
  verifySubnet: `${adnsResolver}/edit-network-object/subnets/verify`,
  profiles: `${adnsResolver}/profiles`,
  addProfile: `${adnsResolver}/profiles/add`,
  editProfile: `${adnsResolver}/profiles/edit`,

  unAuthorized: `${adnsResolver}/unauthorized`,
} as const;
